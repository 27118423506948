.projects_section {
    height: max-content;
    width: 70%;
    margin-bottom: 10rem;
}

.projects_head {
    text-align: left;
    font-size: 3rem;
    font-weight: 700;

    border-bottom: 0.25rem solid black;


    height: max-content;
    margin: 5rem 0;
}

.projects_head > h2 {
    margin: 0;
}

.projects_container {
    width: 100%;

    display: flex;
    flex-direction: column;
}


.project {
    height: 10rem;

    display: flex;
    justify-content: space-between;
    align-items: center;

    margin-bottom: 5rem;
    text-decoration: none;
}




.project_links {
    height: 100%;
    width: 40rem;

    display: grid;
    grid-template-columns: repeat(2, 1fr);
}

.project_name {
    font-size: 2rem;
    font-weight: 700;
    grid-column: 1 / span 2;

    color: black;
}

.project_links > a {
    background-color: black;
    padding: 1rem 2rem;

    font-weight: 700;
    text-decoration: none;
    color: white;
}



.project_image {
    width: 20rem;
}






/* media queries */

@media screen and (max-width: 1250px) {
    

    .projects_container {
        height: max-content;
    }

    .project {
        text-align: center;
        flex-direction: column;

        height: 100%;
    }

    .project_links {
        width: 100%;
        justify-items: center;
        margin-bottom: 2rem;
    }

    .project_image {
        width: 16rem; 
    }
}



@media screen and (max-width: 725px) {
    .projects_section {
        width: 90%;
    }

    .projects_head {
        font-size: 2.5rem;
        margin-bottom: 5rem;
    }

    .project_name {
        font-size: 2rem;
    
        margin: 2rem 0;
    }

    .project_links > a {
        font-size: 1rem;
        padding: 1rem 0rem;
    }

    .project_image {
        width: 13rem; 
    }
}

@media screen and (max-width: 500px) {
    .project_links {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .project_links > a {
        margin: 1rem 0rem;
    }
}




