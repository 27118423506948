
.fold_section {
    height: 100vh;
    width: 70%;
    margin-bottom: 5rem;

    display: flex;
    justify-content: center;
    align-items: center;
}

.background_text {
    position: absolute;
    left: 0;
    z-index: -2;

    align-self: center;
}

.background_text > p {
    font-size: 14rem;
    font-weight: 700;
    line-height: 14rem;

    color: white;
    text-shadow: 0rem 0rem 2.5rem rgb(78, 78, 78);

    opacity: 0.1;
    margin: 0;


    /* disabling the carat for the background text */

    -webkit-user-select: none;  /* Chrome all / Safari all */
    -moz-user-select: none;     /* Firefox all */
    -ms-user-select: none;      /* IE 10+ */
     user-select: none;    
}

.content_container {
    height: max-content;
    display: flex;
    justify-content: space-between;

    width: 100%;
}

.content_container > img {
    height: 29rem;
    filter: grayscale(1);

    border: 0.15rem solid black;
}


.content_text {

    display: flex;
    flex-direction: column;
    justify-content: center;
}


.content_text > h1 {
    font-size: 4rem;
    margin: 0;
}

.content_text > p {
    font-size: 1.2rem;

    margin: 2rem 0;
}

.content_text > a {
    font-size: 1.5rem;
    font-weight: 700;
    text-decoration: none;
    color: white;
    
    height: 4rem;


    background-color: black;
    border: none;

    cursor: pointer;

    display: flex;
    align-items: center;
    justify-content: center;
}




/* media queries */

@media screen and (max-width: 1250px) {
    .background_text > p {
        font-size: 20vw;
    }

    .fold_section {
        width: 90%;
    }

    .content_container {
        justify-content: center;
    }

    .content_container > img {
        display: none;
    }

}

@media screen and (max-width: 725px) {


    .content_text > h1 {
        font-size: 2.5rem;
        margin: 0;
    }

    .content_text > p {
        font-size: 1rem;
    
        margin: 2rem 0;
    }

    .content_text > a {
        font-size: 1.2rem;
        height: 3rem;
    }
}

@media screen and (max-width: 500px) {
    .background_text {
        display: none;
    }

    .content_container {
        text-align: center;
    }

    .content_text {
        align-items: center;
    }

    .content_text > a {
        margin: 0;
    }
}