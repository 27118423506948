@import url('https://fonts.googleapis.com/css2?family=Overpass:ital,wght@0,100..900;1,100..900&display=swap');

.nav {
    position: fixed;
    background-color: #415a77;

    font-family: "Overpass", sans-serif;
    z-index: 50;
}








.navbar {
    list-style-type: none;
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.navbar > a {
    text-decoration: none;
    color: white;

    font-size: 4rem;
    font-weight: 700;
    line-height: 8rem;

    transition: 0.2s;
}

.navbar > a:hover {
    transform: scale(1.1);
}




.hamburger_menu_open {
    position: fixed;
    z-index: 51;
    right: 4.6rem;
    top: 3.6rem;

    width: 2rem;
    height: 1.5rem;
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    cursor: pointer;
}

.hamburger_menu_open > div {
    width: 100%;
    height: 0.3rem;
    background-color: white;
}





.hamburger_menu {
    position: fixed;
    right: 4.6rem;
    top: 3.6rem;
    z-index: 51;

    width: 2rem;
    height: 1.5rem;
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    cursor: pointer;
}

.hamburger_menu > div {
    width: 100%;
    height: 0.3rem;

    background-color: black;
}



.burger_button {
    height: 100%;
    width: 100%;
    padding: 0;
    margin: 0;

    background-color: transparent;
    border: transparent;
    z-index: 50;

    position: absolute
}






.close_list {
    display: none;
    transition: 0;
}



@media screen and (max-width: 1000px) {
    .hamburger_menu_open {
        right: 3.3rem;
        top: 2.6rem;
    }

    .hamburger_menu {
        right: 3.3rem;
        top: 2.6rem;
    }
}

@media screen and (max-width: 750px) {
    .navbar > a {
        font-size: 2rem;
    }
}

@media screen and (max-width: 500px) {
    .hamburger_menu_open {
        right: 2rem;
        top: 1.6rem;
    }

    .hamburger_menu {
        right: 2rem;
        top: 1.6rem;
    }
}