.contact {
    height: max-content;
    width: 70%;
    margin-bottom: 10rem;

}

.header {
    height: max-content;
}

.header > p {
    font-size: 4rem;
    font-weight: 700;
    margin: 0;
}

.input_fields {
    height: max-content;
    width: 100%;

    display: flex;
    flex-direction: column;
}

.input_fields > div {
    margin: 0.5rem 0rem;
}


.input_label {
    font-size: 1.2rem;
    font-weight: 700;
    margin-bottom: 0.5rem;

    height: 1rem;
}

.text_input {
    font-size: 1rem;
    height: 3rem;
    padding: 0.5rem;

    border: 0.15rem solid black;

    display: flex;
    align-items: center;
    width: 100%;
    height: 1rem;
}



.text_content {
    border: 0.15rem solid black;
    padding: 0.5rem;

    height: 15rem;
    width: 100%;

    margin-bottom: 1rem;
    resize: none;
}

.send_button {
    height: 4rem;
    width: 15rem;

    font-size: 1rem;
    font-weight: 700;
    color: white;

    border: none;
}



@media screen and (max-width: 725px) {
    .header > p  {
        font-size: 2.5rem;
    }
}

@media screen and (max-width: 500px) {
    .contact {
        width: 90%;
        margin-bottom: 5rem;
    }

    .text_input {
        padding: 0.5rem 0rem 0.5rem;
    }

    .text_content {
        padding: 0.5rem 0rem 0.5rem;
        height: 50vh;
    }
}